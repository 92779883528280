import { Availability, AvailabilityStatus } from "@pentacode/core/src/model";
import {
    getRange,
    dateAdd,
    formatDateShort,
    formatWeekDayShort,
    parseDateString,
    getDayOfWeek,
    toDateString,
} from "@pentacode/core/src/util";
import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { StateMixin } from "../../mixins/state";
import { Routing } from "../../mixins/routing";
import { singleton } from "../../lib/singleton";
import { app } from "../../init";
import { shared } from "../../styles";
import { alert } from "../alert-dialog";
import "../scroller";
import { AvailabilityDialog } from "../availability-dialog";
import { getHolidayForDate, Holiday } from "@pentacode/core/src/holidays";
import "../popover";
import "../date-picker";
import { Popover } from "../popover";
import { DateString } from "@pentacode/openapi/src/units";

type DayData = { date: DateString; isSunday: boolean; holiday: Holiday | null; availabilities: Availability[] };

@customElement("ptc-staff-availabilities")
export class Availabilities extends Routing(StateMixin(LitElement)) {
    routePattern = /^(home|availabilities)/;

    async connectedCallback() {
        super.connectedCallback();
        await app.loaded;
        if (app.account) {
            this._load();
        }
    }

    @property({ reflect: true, type: Boolean })
    expanded = false;

    @property({ attribute: false })
    date: DateString;

    @state()
    private _loading = false;

    @state()
    private _availabilities: Availability[] = [];

    @state()
    private _days: DayData[] = [];

    @singleton("ptc-availability-dialog")
    private _availabilityDialog: AvailabilityDialog;

    private get _employee() {
        return app.profile;
    }

    private get _range() {
        return getRange(this.date, "week");
    }

    refresh() {
        return this._load();
    }

    async updated(changes: Map<string, unknown>) {
        if (changes.has("date")) {
            await this._load();
        }
    }

    private async _load() {
        if (!this._employee) {
            return;
        }

        const { from, to } = this._range;

        this._loading = true;

        try {
            this._availabilities = await app.api.getAvailabilities({ employees: [this._employee.id], from, to });

            const days: DayData[] = [];
            let date = from;
            while (date < to) {
                days.push({
                    date,
                    isSunday: getDayOfWeek(date) === 0,
                    holiday: getHolidayForDate(date, {
                        holidays: app.venues[0].enabledHolidays,
                        country: app.company!.country,
                    }),
                    availabilities: this._availabilities.filter((a) => a.date === date),
                });
                date = dateAdd(date, { days: 1 });
            }
            this._days = days;
        } catch (e) {
            alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _newAvailability(date: DateString) {
        const created = await this._availabilityDialog.show(
            new Availability({
                employeeId: this._employee!.id,
                status: AvailabilityStatus.Available,
                date,
            })
        );
        if (created) {
            this._load();
        }
    }

    private async _editAvailability(av: Availability, e?: Event) {
        e?.stopPropagation();
        const edited = await this._availabilityDialog.show(av);
        if (edited) {
            this._load();
        }
    }

    static styles = [
        shared,
        css`
            :host {
                position: relative;
            }

            .sticky {
                z-index: 3;
                left: 0;
            }

            .horizontal-view,
            .vertical-view {
                will-change: opacity;
                transition: opacity 0.3s;
            }

            :host([expanded]) .horizontal-view,
            :host(:not([expanded])) .vertical-view {
                opacity: 0;
                pointer-events: none;
            }
        `,
    ];

    private _renderAvailability(av: Availability, compact = false) {
        return html`
            <div
                class="${!compact ? "margined click" : ""} padded box horizontal center-aligning layout"
                style="--color-highlight: ${av.color}"
                @click=${!compact ? (e: Event) => this._editAvailability(av, e) : null}
            >
                <div class="stretch ellipsis">
                    <i class="${av.icon}"></i>
                    ${av.fullLabel}
                </div>
                ${av.repeats !== "never" && !compact
                    ? html`
                          <div class="smaller subtle">
                              <i class="history"></i>
                              ${av.repeatsLabel}
                          </div>
                      `
                    : ""}
            </div>
        `;
    }

    private _renderHorizontal() {
        return html`
            <div
                class="horizontal scroller horizontal-view hide-scrollbar"
                style="margin-top: -0.5em;"
                ?hidden=${this.expanded}
            >
                <div class="margined horizontally-padded spacing start-aligning horizontal layout">
                    ${this._days.map(
                        ({ date, availabilities }) => html`
                            <div class="start-aligning vertical layout">
                                <div class="padded background blue colored-text sticky">
                                    <strong>${formatWeekDayShort(date)}</strong>, ${formatDateShort(date)}
                                </div>

                                <div class="spacing horizontal layout">
                                    ${availabilities.length
                                        ? availabilities.map((av) => this._renderAvailability(av, true))
                                        : html` <div class="padded semibold faded">Keine Angaben</div> `}
                                </div>
                            </div>
                        `
                    )}
                </div>
            </div>
        `;
    }

    render() {
        return html`${this._renderVertical()} ${this._renderHorizontal()}`;
    }

    private _renderVertical() {
        const emp = this._employee;
        if (!emp) {
            return;
        }
        const { from, to } = this._range;
        const today = toDateString(new Date());
        return html`
            <div class="fullbleed vertical layout vertical-view" ?hidden=${!this.expanded}>
                <ptc-scroller class="stretch">
                    <div class="vertical layout fill-vertically">
                        ${this._days.map(
                            ({ date, isSunday, holiday, availabilities }) => html`
                                <div
                                    class="stretch padded border-bottom click"
                                    @click=${() => this._newAvailability(date)}
                                    style="min-height: 5em"
                                >
                                    <div class="padded horizontal layout">
                                        <div class="stretch ${isSunday ? "orange colored-text" : ""}">
                                            <strong>${formatWeekDayShort(date)}</strong>, ${formatDateShort(date)}
                                        </div>
                                        ${holiday ? html` <div class="violet colored-text">${holiday.name}</div> ` : ""}
                                    </div>
                                    ${availabilities.map((av) => this._renderAvailability(av))}
                                </div>
                            `
                        )}
                    </div>
                </ptc-scroller>

                <div class="padded horizontal center-aligning spacing layout">
                    <div class="horizontal layout" style="min-width: 8em;">
                        <button
                            class="transparent"
                            type="button"
                            @click=${() => this.go(null, { date: dateAdd(this.date, { days: -7 }) })}
                        >
                            <i class="arrow-left"></i>
                        </button>
                        <button
                            class="transparent"
                            type="button"
                            @click=${() => this.go(null, { date: today })}
                            ?disabled=${this.date === today}
                        >
                            <i class="calendar-day"></i>
                        </button>
                    </div>
                    <div class="centering horizontal layout stretch">
                        <button class="transparent">
                            <strong>${formatDateShort(from)}</strong> -
                            <strong>${formatDateShort(dateAdd(to, { days: -1 }))}</strong> ${parseDateString(
                                this.date
                            )?.getFullYear()}
                        </button>
                        <ptc-popover positioning="absolute" alignment="top">
                            <ptc-date-picker
                                mode="week"
                                .value=${this.date}
                                @change=${(e: CustomEvent<{ value: string }>) => {
                                    this.go(null, { date: e.detail.value });
                                    ((e.target as HTMLElement).parentElement as Popover).hide();
                                }}
                            ></ptc-date-picker>
                        </ptc-popover>
                    </div>
                    <div class="horizontal end-justifying layout" style="min-width: 8em;">
                        <button
                            class="transparent"
                            type="button"
                            @click=${() => this.go(null, { date: dateAdd(this.date, { days: 7 }) })}
                        >
                            <i class="arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="fullbleed center-aligning vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
