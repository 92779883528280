import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { GetInviteResponse, JoinCompanyParams } from "@pentacode/core/src/api";
import { shared } from "../styles";
import { app } from "../init";
import { alert } from "./alert-dialog";
import { routeProperty, Routing } from "../mixins/routing";
import { StateMixin } from "../mixins/state";
import Logo from "../img/logo.svg";
import "./spinner";
import { Role } from "@pentacode/core/src/model";

@customElement("ptc-join-company")
export class JoinCompanyView extends StateMixin(Routing(LitElement)) {
    routePattern = /join\/(?<id>[^\/]+)?/;

    @property()
    scope: "manage" | "staff" = "manage";

    @query("form")
    _form: HTMLFormElement;

    @query("input[name='repeatPassword']")
    private _repeatPasswordInput: HTMLInputElement;

    @routeProperty({ arg: "id" })
    private _inviteId: string;

    @state()
    private _loading = false;

    @state()
    private _inviteInfo: GetInviteResponse;

    @state()
    private _page: "submit" | "success" = "submit";

    handleRoute() {
        if (!this._inviteId) {
            return;
        }
        this._load();
    }

    private async _load() {
        this._loading = true;
        try {
            this._inviteInfo = await app.api.getInvite(this._inviteId);
            this._page = "submit";
        } catch (e) {
            await alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();

        this._loading = true;

        const password = new FormData(this._form).get("password") as string;

        try {
            await app.api.joinCompany(new JoinCompanyParams({ inviteId: this._inviteId, password, scope: this.scope }));
            this._page = "success";
        } catch (e) {
            await alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _checkRepeatedPassword() {
        const formData = new FormData(this._form);
        this._repeatPasswordInput.setCustomValidity(
            this._inviteInfo?.hasAccount || formData.get("password") === formData.get("repeatPassword")
                ? ""
                : "Sie haben das Passwort nicht richtig wiederholt. " + "Bitte versuchen Sie es erneut!"
        );
    }

    static styles = [
        shared,
        css`
            :host {
                z-index: 100;
                background: #fafafa;
            }

            .logo {
                width: 16em;
            }

            .wrapper {
                width: 100%;
                max-width: 26em;
                padding: 1em;
                box-sizing: border-box;
            }

            a {
                text-decoration: none;
            }
        `,
    ];

    render() {
        if (!this._inviteInfo) {
            return;
        }

        const {
            hasAccount,
            profile,
            inviter,
            company,
            invite,
            profile: { email },
        } = this._inviteInfo;

        return html`
            <div class="fullbleed scrolling">
                <div class="fill vertical centering layout">
                    <div class="wrapper" ?hidden=${this._page !== "submit"}>
                        <div class="centering layout">
                            <img class="top-margined logo" src=${Logo} />
                        </div>

                        <h1 class="text-centering">${hasAccount ? "Unternehmen Beitreten" : "Zugang Einrichten"}</h1>

                        <div class="double-margined text-centering">
                            Hallo <strong>${profile.firstName}</strong>! ${inviter.name} hat Sie eingeladen, Ihren
                            Mitarbeiter-Zugang für das Unternehmen <strong>${company.name}</strong> einzurichten!
                        </div>

                        ${invite.message
                            ? html`
                                  <div class="double-margined blue padded text-centering box">
                                      <strong>Nachricht:</strong> "${invite.message}"
                                  </div>
                              `
                            : ""}

                        <div class="card double-padded vertical layout">
                            <form class="vertical layout" @input=${this._checkRepeatedPassword} @submit=${this._submit}>
                                <div class="subtle text-centering">
                                    ${!hasAccount
                                        ? "Bitte wählen Sie Ihr Passwort:"
                                        : "Bitte melden Sie sich mit Ihrem Passwort an:"}
                                </div>

                                <div class="field">
                                    <label>Emailadresse</label>
                                    <input type="email" required readonly .value=${email || ""} />
                                </div>

                                <div class="field">
                                    <label>Passwort</label>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Passwort"
                                        required
                                        minlength="8"
                                    />
                                </div>

                                <div class="field" ?hidden=${hasAccount}>
                                    <label>Passwort Wiederholen</label>
                                    <input
                                        type="password"
                                        name="repeatPassword"
                                        placeholder="Passwort Wiederholen"
                                        ?required=${!hasAccount}
                                    />
                                </div>

                                <button type="submit" class="primary">
                                    ${hasAccount ? "Unternehmen Beitreten" : "Zugang Einrichten"}
                                </button>
                            </form>
                        </div>
                    </div>

                    <div class="wrapper" ?hidden=${this._page !== "success"}>
                        <div class="double-margined centering layout">
                            <img class="top-margined logo" src=${Logo} />
                        </div>

                        <div class="margined huge text-centering green colored-text">
                            <i class="check"></i> Zugang Eingerichtet
                        </div>

                        <div class="double-margined text-centering">
                            Du hast deinen Mitarbeiter-Zugang für
                            <strong>${this._inviteInfo.company.name}</strong> erfolgreich eingerichtet! Möchtest du dich
                            jetzt einloggen?
                        </div>

                        <div class="double-margined spacing vertical layout">
                            <a
                                href="${process.env.PTC_STAFF_URL!}?email=${encodeURIComponent(email)}"
                                class="big text-centering padded blue box"
                            >
                                <i class="users"></i> Zur Mitarbeiter-App <i class="smaller arrow-right"></i>
                            </a>
                            <a
                                href="${process.env.PTC_MANAGE_URL!}?email=${encodeURIComponent(email)}"
                                class="big padded text-centering orange box"
                                ?hidden=${this._inviteInfo.profile.role > Role.Manager}
                            >
                                <i class="user-crown"></i> Zum Verwaltungszugang <i class="smaller arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
